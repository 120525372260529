import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { createAuthClient } from "../app/security"
import { Tab } from "@mui/material"

type ProtectedProps = {
    anyRole?:string[],
    anyPermission?:string[],
    allPermissions?:string[],
    label:string,
    value?:string,
    props?:any
}

const ProtectedTab = ({anyRole,anyPermission,allPermissions,label,value,...props}:ProtectedProps) => {
    const navigate = useNavigate()
    const [hasPermission, setHasPermission] = useState(true)
    const authClientPromise = useMemo(async () => await createAuthClient(),[])

    useEffect(() => {
        (async () => {
            const authClient = await authClientPromise
    
            const auth = await authClient.isAuthenticated()
            if (!auth) {
                navigate('/', { replace: true })
            }
    
            if (anyRole && anyRole.length > 0) {
                setHasPermission(false)
                let currentUserClaims = await authClient.getIdTokenClaims();
                if (currentUserClaims) {
                    const userRoles = currentUserClaims['https://icetime.app/roles'];
                    if (!userRoles || userRoles.length == 0) {
                        setHasPermission(false)
                    } else {
                        let userHasAnyRole = false;
                        for (let i=0; i<anyRole.length; i++) {
                            let requiredRole = anyRole[i];
                            let userIsInRole = userRoles.some(ur => ur === requiredRole);
                            if (userIsInRole) {
                                userHasAnyRole = true
                                break
                            }
                        }
    
                        setHasPermission(userHasAnyRole)
                    }
                }         
            } else if (anyPermission && anyPermission.length > 0) {
                setHasPermission(false)
                let currentUserClaims = await authClient.getIdTokenClaims();
                
                if (currentUserClaims) {
                    const userPermissions = currentUserClaims['permissions'];
    
                    // Do the easy thing and validate that the user has any permissions whatsoever.
                    // If they don't then we know they don't meet the required permission.
                    if (!userPermissions || userPermissions.length == 0) {
                        setHasPermission(false);
                    } else {
                        let userHasAnyPermission = false;
                        for (let i=0; i<anyPermission.length; i++) {
                            let requiredPermission = anyPermission[i];
                            let userHasPermission = userPermissions.some(up => up === requiredPermission);
                            if (userHasPermission) {
                                userHasAnyPermission = true;
                                break;
                            }
                        }
    
                        setHasPermission(userHasAnyPermission);
                    }
                }
    
            } else if (allPermissions && allPermissions.length > 0) {
                let currentUser = await authClient.getUser();
                const userPermissions = currentUser.permissions;
    
                // Do the easy thing first and validate using length. If the user has fewer permissions
                // than what's required then we know they don't have all of the required permissions.
                if (userPermissions.length < allPermissions.length) {
                    setHasPermission(false);
                } else {
                    for (let i=0; i<allPermissions.length; i++) {
                        let requiredPermission = allPermissions[i]
                        let userHasPermission = userPermissions.some(up => up === requiredPermission)
                        if (!userHasPermission) {
                            setHasPermission(false)
                            break;
                        }
                    }
                }
            }
    
        })()
    },[navigate,anyRole,anyPermission,allPermissions])

    return hasPermission ? <Tab label={label} value={value} {...props} /> : <></>
}

export default ProtectedTab