import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Title from "../Title"
import Link from "@mui/material/Link"
import { Box, Button, Stack, TableSortLabel, TextField } from "@mui/material"
import UserFeedback from "../../types/UserFeedback"
import { defaultDateTimeOptions, feedbackStatusOptions, feedbackSubjectOptions } from "../../app/constants"
import ColumnSort from "../../types/ColumnSort"
import { visuallyHidden } from "@mui/utils"
import FeedbackDetailDialog from "./FeedbackDetailDialog"
import Protected from "../Protected"
import { useDeleteFeedbackMutation } from "../../services/feedbackApi"


type FeedbackGridProps = { messages?:UserFeedback[], showSearchBox?:boolean|false, afterSave?:Function, handleSearch?:Function, sort?:ColumnSort, handleSort?:Function }
const FeedbackGrid = (props:FeedbackGridProps) => {
    
    const { messages, showSearchBox, afterSave, handleSearch, sort, handleSort } = props
    const [openDialog,setOpenDialog] = useState(false)
    const [selectedMessage, setSelectedMesssage] = useState({} as unknown as UserFeedback)
    const [deleteFeedback] = useDeleteFeedbackMutation()

    const openFeedbackDetailPanel = (message:UserFeedback) => {
        setSelectedMesssage(message)
        openDetailDialog()
    }

    const openDetailDialog = () => {
        setOpenDialog(true)
      }
    
      const closeDetailDialog = () => {
        setOpenDialog(false)
      }    

    const onSearchMessages = (evt:any) => {
        handleSearch && handleSearch(evt.currentTarget.value.toLowerCase())
    }

    const handleDeleteFeedback = async (message:UserFeedback) => {
        await deleteFeedback(message)
        afterSave && afterSave()
    }

    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>User Feedback</Title>
                <Stack direction="row" justifyContent="flex-end">
                { showSearchBox && <TextField size="small" style={{ marginRight: 15 }} name="series-search" id="series-search" onChange={onSearchMessages} placeholder="Search"></TextField>}    
                </Stack>                
            </Stack>

            { <>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "name"}
                            direction={sort?.sortBy === "name" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "name",
                                sortDirection: sort.sortBy === "name" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Name
                                {sort?.sortBy === "name" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "email"}
                            direction={sort?.sortBy === "email" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "email",
                                sortDirection: sort.sortBy === "email" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Email
                                {sort?.sortBy === "email" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "subject"}
                            direction={sort?.sortBy === "subject" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "subject",
                                sortDirection: sort.sortBy === "subject" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Subject
                                {sort?.sortBy === "subject" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "feedbackstatus"}
                            direction={sort?.sortBy === "feedbackstatus" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "feedbackstatus",
                                sortDirection: sort.sortBy === "feedbackstatus" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Status
                                {sort?.sortBy === "feedbackstatus" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "lastmodified"}
                            direction={sort?.sortBy === "lastmodified" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "lastmodified",
                                sortDirection: sort.sortBy === "lastmodified" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Last Modified
                                {sort?.sortBy === "lastmodified" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {messages && messages.length > 0 ? ([...messages].map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                            <Link
                                component="button"
                                onClick={() => {
                                    openFeedbackDetailPanel(row)
                                }}>{feedbackSubjectOptions.find(opt => opt.value == row.subject).label}</Link>
                        </TableCell>
                        <TableCell>{feedbackStatusOptions.find(opt => opt.value == row.feedbackStatus)?.label}</TableCell>
                        <TableCell>{new Date(row.lastModified).toLocaleString("en-US", defaultDateTimeOptions.dateTimeOptions)}</TableCell>
                        <TableCell>
                            <Stack direction="row" justifyContent="flex-end">
                                <Protected
                                    anyRole={["IceTime Admin"]}
                                ><Button sx={{m:1}} color="error" variant="contained" data-id={row.id} onClick={() => handleDeleteFeedback(row)}>Delete</Button></Protected> 
                            </Stack>    
                        </TableCell> 
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={7}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                <FeedbackDetailDialog feedback={selectedMessage} open={openDialog} onClose={closeDetailDialog} fullWidth={true} maxWidth="md" />
                </>}                        
        </div>
    )
}

export default FeedbackGrid