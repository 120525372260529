import React from "react"
import { createBrowserRouter } from "react-router-dom"
import OrganizationPage from "../pages/OrganizationPage"
import OrganizationDetailPage from "../pages/OrganizationDetailPage"
import FacilityPage from "../pages/FacilityPage"
import FacilityDetailPage from "../pages/FacilityDetailPage"
import SchedulePage from '../pages/SchedulePage'
import ScheduleDetailPage from "../pages/ScheduleDetailPage"
import SeriesPage from '../pages/SeriesPage'
import SeriesDetailPage from "../pages/SeriesDetailPage"
import LoginPage from "../pages/LoginPage"
import UserPage from "../pages/UserPage"
import UserDetailPage from "../pages/UserDetailPage"
import ProtectedRoute from "../components/ProtectedRoute"
import FeedbackPage from "../pages/FeedbackPage"

export const router = createBrowserRouter([
{
    path: "/",
    element: <LoginPage />,
    index: true
}, {
    path: "/login",
    element: <LoginPage />
}, {
    element: <ProtectedRoute />,
    children: [
        {
            element: <ProtectedRoute anyRole={["IceTime Admin"]}/>,
            children: [
                {
                    path: "/users",
                    element: <UserPage />
                }, {
                    path: "/users/:id?",
                    element: <UserDetailPage />
                }, {
                    path: "/feedback",
                    element: <FeedbackPage />
                }                
            ]
        },{
            element: <ProtectedRoute anyRole={["IceTime Admin","Organization Admin"]}/>,
            children: [
                {
                    path: "/organizations",
                    element: <OrganizationPage />
                }, {
                    path: "/organizations/:id?",
                    element: <OrganizationDetailPage />
                }
            ]
        }, {
            path: "/facilities",
            element: <FacilityPage />
        }, {
            path: "/facilities/:id?",
            element: <FacilityDetailPage />
        }, {
            path: "/schedules",
            element: <SchedulePage />
        }, {
            path: "/schedules/:id?",
            element: <ScheduleDetailPage />
        }, {
            path: "/series",
            element: <SeriesPage />
        }, {
            path: "/series/:id?",
            element: <SeriesDetailPage />
        }, {
            path: "/logout",
            element: <LoginPage logout={true} />
        }
    ]
} 
])
