import Dashboard from "./Dashboard";
import FacilityGrid from "../components/facility/FacilityGrid";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useGetAllFacilitiesQuery, useLazyGetFacilityPageQuery, useLazyFindFacilitiesBySearchTermPageQuery } from "../services/facilityApi";
import { useEffect, useState } from "react";
import Paged from "../types/Paged";
import Facility from "../types/Facility";
import { useDebounce } from "../app/hooks";
import { GetPagedArgs } from "../app/queries";
import { pagination } from "../app/constants";
import ColumnSort from "../types/ColumnSort";
import FacilitiesMap from "../components/facility/FacilitiesMap";

const FacilityPage = (props:any) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(pagination.defaultItemsPerPage)
    const [sort, setSort] = useState({ sortBy: "name", sortDirection: "asc"} as ColumnSort)
    const [isLoading, setIsLoading] = useState(false)
    const [facilityPage, setFacilityPage] = useState<Paged<Facility>>(undefined)
    const [searchTerm, setSearchTerm]= useState<string|undefined>(undefined)
    const [getFacilityPage] = useLazyGetFacilityPageQuery()
    const [findFacilitiesBySearchTermPage] = useLazyFindFacilitiesBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    useEffect(() => {
      setIsLoading(true)
      getFacilities().then(_ => setIsLoading(false))
    },[pageNumber,itemsPerPage,sort,debouncedSearchTerm])    
    
    const getFacilities = async () => {
      if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
        await fetchFacilityPage()
      } else {
        await searchFacilityPage()
      }
    }

    const fetchFacilityPage = async () => {
      const pageArgs : GetPagedArgs = {
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      const sp = await getFacilityPage(pageArgs)
      setFacilityPage(sp.data)
    }

    const searchFacilityPage = async () => {
      const pageArgs : GetPagedArgs = {
        searchTerm: debouncedSearchTerm,
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      const sp = await findFacilitiesBySearchTermPage(pageArgs)
      setFacilityPage(sp.data)
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      setSort(updatedSort)
      setPageNumber(1)
    }

    const handleSave = () => {
      getFacilities()
      setPageNumber(1)
      //setSort({ sortBy: "name", sortDirection: "asc" })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      setPageNumber(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setItemsPerPage(+event.target.value);
      setPageNumber(1);
    };
    
    const handleSearch = (searchTerm:string) => {
      setSearchTerm(searchTerm)
      setPageNumber(1)
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <FacilityGrid facilities={facilityPage ? facilityPage.items : []} allowNewFacility={true} showSearchBox={true} afterSave={handleSave} handleSearch={handleSearch} sort={sort} handleSort={handleSort} />
                    <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={facilityPage?.totalItems ?? 0}
                          rowsPerPage={itemsPerPage}
                          page={pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />                    
                    </>  }
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <FacilitiesMap facilities={facilityPage ? facilityPage.items : []}></FacilitiesMap>                    
                    </>  }
                </Paper>
              </Grid>              
            </Grid>
        </Dashboard>        
    )
}

export default FacilityPage