import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface SearchState {
    facilitySearchTerm?: string,
    seriesSearchTerm?: string,
    organizationSearchTerm?: string,
    userSearchTerm?: string,
    scheduleSearchTerm?: string 
}

const initialState = {
     facilitySearchTerm: null,
     seriesSearchTerm: null,
     organizationSearchTerm: null,
     userSearchTerm: null,
     scheduleSearchTerm: null 
} satisfies SearchState as SearchState

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setFacilitySearchTerm: (state, action: PayloadAction<string>) => {
            state.facilitySearchTerm = action.payload
        },
        setSeriesSearchTerm: (state, action: PayloadAction<string>) => {
            state.seriesSearchTerm = action.payload
        },
        setOrganizationSearchTerm: (state, action: PayloadAction<string>) => {
            state.organizationSearchTerm = action.payload
        },
        setUserSearchTerm: (state, action: PayloadAction<string>) => {
            state.userSearchTerm = action.payload
        },
        setScheduleSearchTerm: (state, action: PayloadAction<string>) => {
            state.scheduleSearchTerm = action.payload
        }
    },
    selectors: {
        selectFacilitySearchTerm: (state) => {
            return state.facilitySearchTerm
        },
        selectSeriesSearchTerm: (state) => {
            return state.seriesSearchTerm
        },
        selectOrganizationSearchTerm: (state) => {
            return state.organizationSearchTerm
        },
        selectUserSearchTerm: (state) => {
            return state.userSearchTerm
        },
        selectScheduleSearchTerm: (state) => {
            return state.scheduleSearchTerm
        }
    }
})

export const { 
    setFacilitySearchTerm,
    setSeriesSearchTerm,
    setOrganizationSearchTerm,
    setUserSearchTerm,
    setScheduleSearchTerm
} = searchSlice.actions
export const { 
    selectFacilitySearchTerm,
    selectSeriesSearchTerm,
    selectOrganizationSearchTerm,
    selectUserSearchTerm,
    selectScheduleSearchTerm 
} = searchSlice.selectors
export default searchSlice