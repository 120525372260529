import { api } from '../app/api'
import { apiConstants } from '../app/constants'
import { FindSessionsByFacilityArgs, GetPagedArgs } from '../app/queries'
import Paged from '../types/Paged'
import Series from '../types/Series'
import Session from '../types/Session'

const seriesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSeriesPage: builder.query<Paged<Series>,GetPagedArgs>({
            query: ({pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `series/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Series> = {
                    items: _embedded && _embedded.series ? _embedded.series : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }
        }),
        findSeriesBySearchTermPage: builder.query<Paged<Series>, GetPagedArgs>({
            query: ({searchTerm, pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('searchTerm',searchTerm);
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `series/search/findByFuzzyCriteria/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Series> = {
                    items: _embedded && _embedded.series ? _embedded.series : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),
        getAllSeries: builder.query<Series[],void>({
            query: () => 'series',
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({id}) => ({ type: 'Series', id: id.toString()} as const)),
                        { type: 'Series', id: 'LIST' },
                    ] :
                    [{ type: 'Series', id: 'LIST'}],
            transformResponse: ({ _embedded }) => {
                return _embedded && _embedded.series ? [..._embedded.series] : []
            }            
        }),
        getSeriesById: builder.query<Series,number>({
            query: (id) => `series/${id}`,
            providesTags: (result) => result ? [{type: 'Series', id: result.id.toString()}] : []
        }),
        getSeriesSessions: builder.query<Session[],number>({
            query: (id) => `series/${id}/sessions`,
            providesTags: (result) => result && result.length > 0 ? [{type: 'SeriesSessions', id: result[0].seriesId.toString()}] : [],
            transformResponse: ({_embedded}) => {
                return _embedded && _embedded.sessions ? [..._embedded.sessions] : []
            }
        }),
        findUpcomingSeriesByFacilityId: builder.query<Series[], FindSessionsByFacilityArgs>({
            query: ({facilityId,showSeriesOnly}) => `series/search/findUpcomingSeriesByFacilityId?facilityId=${facilityId}&daysOut=365&showSeriesOnly=${showSeriesOnly}`,
            providesTags: (result) =>
            // is result available?
            result
              ? // successful query
                [
                  ...result.map(({ id }) => ({ type: 'Series', id: id.toString() } as const)),
                ]
              : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                [],
            transformResponse: ({ _embedded }, meta, arg) => {
              return _embedded && _embedded.series ? _embedded.series?.map((series: Series) => series) : []
            }        
          }),          
        generateSessions: builder.mutation({
            query: (id:number) => {
                return {
                    url: `series/${id}/generateSessions`,
                    method: 'PUT',
                }
            },
            invalidatesTags: (result, error, id) => [{type: 'Series', id: id.toString()},{type: 'SeriesSessions', id: id.toString()}]
        }),
        createSeries: builder.mutation({
            query: (series:Series) => {
                return {
                    url: `series`,
                    method: 'POST',
                    body: {
                        ...series,
                        facility: `${apiConstants.BASE_URL}/facilities/${series.facilityId}`,
                        organization: `${apiConstants.BASE_URL}/organizations/${series.organizationId}`,
                        schedule: `${apiConstants.BASE_URL}/schedules/${series.scheduleId}`
                    }
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Series', id: 'LIST'}]
        }),
        updateSeries: builder.mutation({
            query: (series:Series) => {
                return {
                    url: `series/${series.id}`,
                    method: 'PATCH',
                    body: {
                        ...series,
                        facility: `${apiConstants.BASE_URL}/facilities/${series.facilityId}`,
                        organization: `${apiConstants.BASE_URL}/organizations/${series.organizationId}`,
                        schedule: `${apiConstants.BASE_URL}/schedules/${series.scheduleId}`
                    }
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Series', id: id.toString()}]
        }),
        deleteSeries: builder.mutation({
            query: (series:Series) => {
                return {
                    url: `series/d/${series.id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Series', id: result.seriesId.toString()}]
        })        
    }),
    overrideExisting: false
})

export const {
    useGetAllSeriesQuery,
    useLazyGetSeriesPageQuery,
    useGetSeriesPageQuery,
    useFindSeriesBySearchTermPageQuery,
    useLazyFindSeriesBySearchTermPageQuery,
    useGetSeriesByIdQuery,
    useGetSeriesSessionsQuery,
    useLazyGetSeriesSessionsQuery,
    useFindUpcomingSeriesByFacilityIdQuery,
    useGenerateSessionsMutation,
    useCreateSeriesMutation,
    useUpdateSeriesMutation,
    useDeleteSeriesMutation,
} = seriesApi