import { useParams } from "react-router-dom"
import { useGetOrganizationByIdQuery } from "../services/organizationApi"
import Dashboard from "./Dashboard"
import { Box, CircularProgress, Grid, Paper, Tab } from "@mui/material"
import OrganizationInfoPanel from "../components/organization/OrganizationInfoPanel"
import OrganizationFacilityGrid from "../components/organization/OrganizationFacilityGrid"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import OrganizationForm from "../components/organization/OrganizationForm"
import { useState } from "react"
import Protected from "../components/Protected"
import ProtectedTab from "../components/ProtectedTab"

const OrganizationDetailPage = () => {
    const {id} = useParams()
    const {data:organization, isLoading:loadingOrg, error:orgError, refetch:refetchOrg} = useGetOrganizationByIdQuery(parseInt(id ?? "0"))
    const [selectedTab, setSelectedTab] = useState('info')
    const handleTabChange = (_evt: React.SyntheticEvent, tabValue: string) => {
      setSelectedTab(tabValue)
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { loadingOrg 
                        ? (<CircularProgress/>) 
                        : (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={selectedTab}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                  <Tab label="Info" value="info" />
                                  <ProtectedTab
                                    anyRole={["IceTime Admin","Organization Admin"]}
                                    label="Update"
                                    value="update"
                                    //protectedElement={<Tab label="Update" value="update" />}
                                  />
                                </TabList>
                              </Box>
                              <TabPanel value="info"><OrganizationInfoPanel organization={organization}/></TabPanel>
                              <Protected
                                anyRole={["IceTime Admin","Organization Admin"]}
                                //protectedElement={<TabPanel value="update"><OrganizationForm organization={organization} afterSave={refetchOrg}/></TabPanel>}
                              ><TabPanel value="update"><OrganizationForm organization={organization} afterSave={refetchOrg}/></TabPanel></Protected>
                            </TabContext>
                          </Box>
                        ) 
                    }
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { loadingOrg
                        ? (<CircularProgress/>)
                        : (<OrganizationFacilityGrid organizationId={organization?.id}/>)}
                    </Paper>
                </Grid>
            </Grid>
        </Dashboard>
    )

}

export default OrganizationDetailPage