import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuthProvider } from '../app/context';
import UnauthorizedPage from '../pages/UnauthorizedPage';
import { createAuthClient } from '../app/security';
import Dashboard from '../pages/Dashboard';
import Protected from './Protected';

type ProtectedRouteProps = {anyRole?:string[], anyPermission?:string[], allPermissions?:string[]}
const ProtectedRoute = ({anyRole,anyPermission,allPermissions}:ProtectedRouteProps) => {

  //return hasPermission ? <Outlet /> : <Dashboard><UnauthorizedPage/></Dashboard>;

  return <Protected
            anyRole={anyRole}
            anyPermission={anyPermission}
            allPermissions={allPermissions}>
            <Outlet />
        </Protected>
};

export default ProtectedRoute;