import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Title from "../Title"
import { Link } from "react-router-dom"
import { Box, Button, IconButton, InputAdornment, Stack, TableSortLabel, TextField } from "@mui/material"
import Facility from "../../types/Facility"
import FacilityFormDialog from "./UserFormDialog"
import User from "../../types/User"
import ColumnSort from "../../types/ColumnSort"
import {visuallyHidden} from "@mui/utils"
import { defaultDateTimeOptions } from "../../app/constants"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectUserSearchTerm, setUserSearchTerm } from "../../slices/searchSlice"
import ClearIcon from '@mui/icons-material/Clear';

type UserGridProps = { users?:User[], allowNewUser?:Boolean, organizationId?:number, handleSearch?:Function, sort?:ColumnSort, handleSort?:Function }
const UserGrid = (props:UserGridProps) => {
    
    const dispatch = useAppDispatch()
    const userSearchTerm = useAppSelector(selectUserSearchTerm)
    const { users, allowNewUser, organizationId, handleSearch, sort, handleSort } = props

    const [openDialog,setOpenDialog] = useState(false)
    const [searchTerm, setSearchTerm] = useState<string|undefined>(userSearchTerm)

    const onSearchUsers = (evt:any) => {
        dispatch(setUserSearchTerm(evt.currentTarget.value.toLowerCase()))
        setSearchTerm(evt.currentTarget.value.toLowerCase())       
    }

    const openFormDialog = () => {
      setOpenDialog(true)
    }
  
    const closeFormDialog = () => {
      setOpenDialog(false)
    }
    
    const clearSearchTerm = () => {
        dispatch(setUserSearchTerm(''))
        setSearchTerm('')
    }

    useEffect(() => {
        handleSearch && handleSearch(searchTerm) 
    },[searchTerm])

    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>Users</Title>
                <Stack direction="row" justifyContent="flex-end">
                <TextField 
                        size="small" 
                        style={{ marginRight: 15 }} 
                        name="series-search" 
                        id="series-search" 
                        onChange={onSearchUsers}  
                        placeholder="Search" 
                        value={searchTerm}
                        InputProps={{endAdornment: (
                            <InputAdornment position="end">
                                {searchTerm ? (<IconButton 
                                children={<ClearIcon/>}
                                edge='end'
                                size='small'
                                onClick={clearSearchTerm}/>) : (<></>)}
                            </InputAdornment>
                        )}}></TextField>
                { allowNewUser && <Button color="primary" variant="contained" onClick={openFormDialog}>New User</Button> }
                </Stack>
            </Stack>

            { <>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "username"}
                            direction={sort?.sortBy === "username" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "username",
                                sortDirection: sort.sortBy === "username" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Username
                                {sort?.sortBy === "username" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "name"}
                            direction={sort?.sortBy === "name" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "name",
                                sortDirection: sort.sortBy === "name" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Name
                                {sort?.sortBy === "name" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "organization"}
                            direction={sort?.sortBy === "organization" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "organization",
                                sortDirection: sort.sortBy === "organization" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Organization
                                {sort?.sortBy === "organization" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "role"}
                            direction={sort?.sortBy === "role" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "role",
                                sortDirection: sort.sortBy === "role" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Role
                                {sort?.sortBy === "role" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>                                                
                        <TableCell>Last Modified</TableCell>
                        <TableCell>Modified By</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {users && users.length > 0 ? ([...users].map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <Link to={`/users/${row.id}`} state={{ searchTerm }}>{row.username}</Link>
                        </TableCell>
                        <TableCell>{row.relatedPerson?.givenName} {row.relatedPerson?.surname}</TableCell>
                        <TableCell>{row.organizationName ? row.organizationName : 'N/A' }</TableCell>
                        <TableCell>{row.roleName ? row.roleName : 'App User' }</TableCell>
                        <TableCell>{new Date(row.lastModified).toLocaleString("en-US", defaultDateTimeOptions.dateTimeOptions)}</TableCell>
                        <TableCell>{row.modifiedBy}</TableCell>
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={8}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                    <FacilityFormDialog organizationId={organizationId} open={openDialog} onClose={closeFormDialog} fullWidth={true} maxWidth="md" />
                </>}                        
        </div>
    )
}

export default UserGrid