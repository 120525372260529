import Dashboard from "./Dashboard";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useGetAllUsersQuery, useLazyFindUsersBySearchTermPageQuery, useLazyGetUserPageQuery } from "../services/userApi";
import UserGrid from "../components/user/UserGrid";
import { useEffect, useState } from "react";
import { pagination } from "../app/constants";
import Paged from "../types/Paged";
import User from "../types/User";
import { useDebounce } from "../app/hooks";
import { GetPagedArgs } from "../app/queries";
import ColumnSort from "../types/ColumnSort";

const UserPage = (props:any) => {
    //const {data:users, error, isLoading} = useGetAllUsersQuery()

    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(pagination.defaultItemsPerPage)
    const [sort, setSort] = useState({ sortBy: "username", sortDirection: "asc"} as ColumnSort)
    const [isLoading, setIsLoading] = useState(false)
    const [userPage, setUserPage] = useState<Paged<User>>(undefined)
    const [searchTerm, setSearchTerm]= useState<string|undefined>(undefined)
    const [getUserPage] = useLazyGetUserPageQuery()
    const [findUsersBySearchTermPage] = useLazyFindUsersBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(searchTerm, 500)    

    useEffect(() => {
      setIsLoading(true)
      getUsers().then(_ => setIsLoading(false))
    },[pageNumber,itemsPerPage,sort,debouncedSearchTerm])    
    
    const fetchUserPage = async () => {
      const pageArgs : GetPagedArgs = {
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      const sp = await getUserPage(pageArgs)
      setUserPage(sp.data)
    }

    const searchUserPage = async () => {
      const pageArgs : GetPagedArgs = {
        searchTerm: debouncedSearchTerm,
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      const sp = await findUsersBySearchTermPage(pageArgs)
      setUserPage(sp.data)
    }
    
    const getUsers = async () => {
      if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
        await fetchUserPage()
      } else {
        await searchUserPage()
      }
    }    

    const handleSave = () => {
      getUsers()
      setPageNumber(1)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      setPageNumber(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setItemsPerPage(+event.target.value);
      setPageNumber(1);
    };
    
    const handleSearch = (searchTerm:string) => {
      setSearchTerm(searchTerm)
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      setSort(updatedSort)
      setPageNumber(1)
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <UserGrid users={userPage ? userPage.items : []} allowNewUser={false} handleSearch={handleSearch} sort={sort} handleSort={handleSort}></UserGrid>
                    <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={userPage?.totalItems ?? 0}
                          rowsPerPage={itemsPerPage}
                          page={pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />                      
                    </> 
                    }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>        
    )
}

export default UserPage