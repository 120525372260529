import { useParams } from "react-router-dom"
import Dashboard from "./Dashboard"
import { Box, CircularProgress, Grid, Paper, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useState } from "react"

import ScheduleForm from "../components/schedule/ScheduleForm"
import { useGetScheduleByIdQuery } from "../services/scheduleApi"
import Schedule from "../types/Schedule"
import ScheduleInfoPanel from "../components/schedule/ScheduleInfoPanel"
import ProtectedTab from "../components/ProtectedTab"
import Protected from "../components/Protected"

const FacilityDetailPage = () => {
    const {id} = useParams()
    const {data:schedule, isLoading, error, refetch:refetchSchedule} = useGetScheduleByIdQuery(parseInt(id ?? "0"))
    const [selectedTab, setSelectedTab] = useState('info')
    const handleTabChange = (_evt: React.SyntheticEvent, tabValue: string) => {
      setSelectedTab(tabValue)
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading 
                        ? (<CircularProgress/>) 
                        : (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={selectedTab}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                  <Tab label="Info" value="info" />
                                  <ProtectedTab label="Update" value="update" anyRole={["IceTime Admin","Organization Admin","Facility Manager", "Scheduler"]} />
                                </TabList>
                              </Box>
                              <TabPanel value="info"><ScheduleInfoPanel schedule={schedule} /></TabPanel>
                              <Protected anyRole={["IceTime Admin","Organization Admin","Facility Manager", "Scheduler"]}>
                                <TabPanel value="update"><ScheduleForm schedule={schedule ?? {} as Schedule} /></TabPanel>
                              </Protected>
                            </TabContext>
                          </Box>
                        ) 
                    }
                    </Paper>
                </Grid>
                {/* <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { loadingOrg
                        ? (<CircularProgress/>)
                        : (<OrganizationFacilityGrid organizationId={organization?.id}/>)}
                    </Paper>
                </Grid> */}
            </Grid>
        </Dashboard>
    )

}

export default FacilityDetailPage