import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Title from "../Title"
import { Link } from "react-router-dom"
import { Button, Stack } from "@mui/material"
import Series from "../../types/Series"
import Session from "../../types/Session"
import { useDeleteSessionMutation } from "../../services/sessionApi"
import SessionFormDialog from "./SessionFormDialog"
import { defaultDateTimeOptions } from "../../app/constants"
import Protected from "../Protected"

type SessionGridProps = { sessions?:Session[], seriesId?:number, scheduleId?:number, showSeriesInfo?:Boolean, showFacilityInfo?:Boolean, afterSave?:Function }
const SeriesGrid = ({sessions,seriesId,scheduleId,showSeriesInfo,showFacilityInfo,afterSave}:SessionGridProps) => {

    const [openDialog,setOpenDialog] = useState(false)
    const [sessionToEdit, setSessionToEdit] = useState({ seriesId: seriesId, scheduleId: scheduleId } as unknown as Session)
    const [sessionDialogTitle, setSessionDialogTitle] = useState("")

    const [deleteSession] = useDeleteSessionMutation()
    //const [clonedSession, setClonedSession] = useState({seriesId: seriesId, scheduleId: scheduleId} as unknown as Session)

    const openFormDialog = () => {
      setOpenDialog(true)
    }
  
    const closeFormDialog = () => {
      setOpenDialog(false)
    }

    const handleCloneSession = (session:Session) => {
        setSessionToEdit({
            seriesId: session.seriesId,
            scheduleId: session.scheduleId,
            startTime: session.startTime,
            endTime: session.endTime
        } as unknown as Session)

        setSessionDialogTitle("New Session")
        openFormDialog()
    }

    const handleDeleteSession = async (session:Session) => {
        // TODO: confirmation dialog
        await deleteSession(session)
        afterSave && afterSave()
    }

    const handleCreateSession = () => {
        setSessionToEdit({ seriesId: seriesId, scheduleId: scheduleId } as unknown as Session)
        setSessionDialogTitle("New Session")
        openFormDialog()
    }

    const handleEditSession = (session:Session) => {
        setSessionToEdit(session)
        setSessionDialogTitle("Edit Session")
        openFormDialog()
    }
    
    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>Sessions</Title>
                <Protected anyRole={["IceTime Admin","Organization Admin","Facility Manager","Scheduler"]}>
                    <Button color="primary" variant="contained" onClick={handleCreateSession}>New Session</Button>
                </Protected>
            </Stack>

            { <>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        { showSeriesInfo ? <TableCell>Series</TableCell> : <></> }
                        <TableCell>Date</TableCell>
                        <TableCell>Day</TableCell>
                        <TableCell>Times</TableCell>
                        { showFacilityInfo ? <TableCell>Facility</TableCell> : <></> }
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {sessions ? ([...sessions].sort((a, b) => (new Date(a.occurrenceDate + ' ' + a.startTime).valueOf() <= new Date(b.occurrenceDate + ' ' + b.startTime).valueOf() ? -1 : 1)).map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        { showSeriesInfo ? 
                        <TableCell>
                            <Link to={`/series/${row.seriesId}`}>{row.seriesName}</Link>
                        </TableCell> : <></> }
                        <TableCell>{new Date(row.occurrenceDate + ' ' + row.startTime).toLocaleDateString('en-US', defaultDateTimeOptions.dateOptions)}</TableCell>
                        <TableCell>{new Date(row.occurrenceDate + ' ' + row.startTime).toLocaleDateString('en-US', defaultDateTimeOptions.weekdayOptions)}</TableCell>                        
                        <TableCell>{new Date(row.occurrenceDate + ' ' + row.startTime).toLocaleTimeString('en-US', defaultDateTimeOptions.timeOptions)} - {new Date(row.occurrenceDate + ' ' + row.endTime).toLocaleTimeString('en-US', defaultDateTimeOptions.timeOptions)}</TableCell>
                        { showFacilityInfo ? 
                        <TableCell>
                            <Link to={`/facilities/${row.facilityId}`}>{row.facilityName}</Link>
                        </TableCell> : <></> }
                        <TableCell>
                            <Protected anyRole={["IceTime Admin", "Organization Admin", "Facility Manager", "Scheduler"]}>
                                <Stack direction="row" justifyContent="flex-end">
                                    <Button sx={{m:1}} color="secondary" variant="outlined" onClick={() => handleCloneSession(row)}>Clone</Button>
                                    <Button sx={{m:1}} color="primary" variant="contained" onClick={() => handleEditSession(row)}>Edit</Button>
                                    <Button sx={{m:1}} color="error" variant="contained" data-id={row.id} onClick={() => handleDeleteSession(row)}>Delete</Button> 
                                </Stack>
                            </Protected>    
                        </TableCell>                                                  
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={4}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                <SessionFormDialog open={openDialog} onClose={closeFormDialog} fullWidth={true} maxWidth="md" session={sessionToEdit} dialogTitle={sessionDialogTitle} afterSave={() => afterSave && afterSave()} />
                </>}                          
        </div>
    )

}

export default SeriesGrid