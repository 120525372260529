import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';

export const createAuthClient = async () => {
    const client = await createAuth0Client({
        domain: 'login.icetimemobile.com',
        clientId: 'Qs8Dc8Puvnd9VA7Rh09D1uUpOE52rEt6',
        cacheLocation: 'localstorage',
        authorizationParams: {
            audience: 'https://api.icetime.app',
            scope: `openid profile email`
        }
    })

    return client;
}